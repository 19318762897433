import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    checkInHistory: []
}

export const checkInHistory = createSlice({
    name: 'checkInHistory',
    initialState,
    reducers: {
        setHistory: (state, action) => {
            state.checkInHistory = action.payload
        }
    },
})

export const { setHistory } = checkInHistory.actions

export default checkInHistory.reducer