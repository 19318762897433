import React from "react";

// ** import third party lib
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { CookiesProvider } from "react-cookie";

// ** import redux
import store, { persistor } from "./store";

// ** import components
import Theme from "components/template/Theme";
import Layout from "components/layout";

// **i mport css
import "./App.css";
import { ToastContainer } from "react-toastify";

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */

function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <CookiesProvider>
            <Theme>
              <Layout />
              <ToastContainer limit={1} />
            </Theme>
          </CookiesProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
