import React, { memo, useMemo, lazy, Suspense } from 'react'
import { Loading } from 'components/shared'
import { useSelector } from 'react-redux'
import {
	LAYOUT_TYPE_CLASSIC,
	LAYOUT_TYPE_MODERN,
	LAYOUT_TYPE_SIMPLE,
	LAYOUT_TYPE_STACKED_SIDE,
	LAYOUT_TYPE_DECKED,
	LAYOUT_TYPE_BLANK
} from 'constants/theme.constant'
import useDirection from 'utils/hooks/useDirection'

const layouts = {
	[LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
	[LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {

	const layoutType = useSelector((state) => state.theme.layout.type)


	useDirection()

	const AppLayout = useMemo(() => {
		return layouts[layoutType]
	}, [layoutType])

	return (
		<Suspense
			fallback={
				<div className="flex flex-auto flex-col h-[100vh]">
					<Loading loading={true} />
				</div>
			}
		>
			<AppLayout />
		</Suspense>
	)
}

export default memo(Layout)