import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import checkInHistory from './checkInHistory'

const reducer = combineReducers({
    session,
    user,
    checkInHistory
})

export default reducer